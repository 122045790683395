<template src='./getexchangerate.html'></template>
<style scoped src="./getexchangerate.css">

</style>
<script>
import Loading from 'vue-loading-overlay'
import fxService from '@/services/FXServices.vue'
import fxConstants from '@/constants/FxConstants.vue'
import modal from '@/components/modal/notification/modal'
export default {
    components: {
        Loading, modal
    },
    data() {
        return {
            fxProvider: '6',
            fxProviderName: '',
            fxAccountList: [],
            sourceCurrency: [],
            selectedSourceCurrency: 'USD',
            destinationCurrency: [],
            selectedDestinationCurrency: '',
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    methods: {
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        removeUnderscoreText(data) {
            if (data) {
                data = Array.isArray(data) ? data : [data]
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text
                    })
                }
                data.map(reciptient)
            } else {
                data = []
            }
            return data
        },
        filterDestinationCurrency() {
            this.destinationCurrency = []
            this.sourceCurrency.forEach(el => {
                if (el !== this.selectedSourceCurrency) {
                    this.destinationCurrency.push(el)
                }
                this.selectedDestinationCurrency = this.destinationCurrency[0]
            })
        },
        async getExchangeRate() {
            try {
                this.loaderVisible = true
                await fxService.methods.GetExchangeRate({
                    DestinationCurrency: this.selectedDestinationCurrency,
                    FXProviderId: this.fxProvider,
                    SourceCurrency: this.selectedSourceCurrency
                }).then(res => {
                    this.showAlert(1, res.csGetExchangeRateResponse.ExchangeRate)
                })
                    .catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        clear() {
            this.selectedSourceCurrency = this.sourceCurrency[0]
            this.filterDestinationCurrency()
        }
    },
    async created() {
        try {
            let reducer = (accumulator, currentValue) => {
                return (accumulator === currentValue?.value) ? currentValue?.text : accumulator
            }
            this.loaderVisible = true
            await fxService.methods.GetFXAccountList('')
                .then(res => {
                    this.fxAccountList = this.removeUnderscoreText(res.csFxGetAccountsResponse.FxAccountList.FXAccountListItem)
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            this.fxProviderName = fxConstants.fxProvider.reduce(reducer, this.fxProvider)
            let fxAcc = this.fxAccountList.filter(el => el.FXProviderName === this.fxProviderName)
            let response = ''
            fxAcc.forEach(el => {
                response += el.Currency + '-' + el.FXAccountId + '~'
            })
            if (response.includes('~')) {
                response = response.substring(0, response.length - 1)
            }
            let res = response.split('~')
            res.forEach(el => {
                let x = el.split('-')
                this.sourceCurrency.push(x[0])
            })
            this.sourceCurrency.forEach(el => {
                if (el !== this.selectedSourceCurrency) {
                    this.destinationCurrency.push(el)
                }
                this.selectedDestinationCurrency = this.destinationCurrency[0]
            })
            this.loaderVisible = false
        } catch (error) {
            this.loaderVisible = false
        }
    },
}
</script>
